import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import "./index.scss";

function Rodape() {
  const navigation = useNavigate();
  return (
    <Container className="sessao-site sessao-rodape">
      <Grid container sx={{ p: 3 }} justifyContent="center">
        <Grid item xs={12} sm={3}>
          <Typography className="imagem">
            <img src="/site/logo_rodape.png" alt=""></img>
          </Typography>
          <Typography className="contato">(55) 99904 2290</Typography>
          <Typography className="contato">ola@movipay.com.br</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          sx={{
            textAlign: { xs: "left", sm: "right" },
          }}>
          <img
            className="redes-sociais"
            alt="whatsapp"
            src="/site/whatsapp.png"
            onClick={() => {
              window.open(
                "https://api.whatsapp.com/send?phone=5555999042290&text=Ol%C3%A1",
                "_blank"
              );
            }}
          />
          <Typography className="endereco">CNPJ: 49.595.786/0001-26</Typography>
          <Typography className="endereco">
            Rua Três Passos, 111 - Bairro São Francisco
          </Typography>
          <Typography className="endereco">CEP 98920-000</Typography>
          <Typography className="endereco">Horizontina RS | Brasil</Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          className="termos-uso"
          justifyContent="center"
          sx={{
            display: "flex",
            flexDirection: "row",
            m: 1,
          }}>
          <Typography
            onClick={() => {
              navigation("/termos-de-uso");
            }}>
            Termos de uso
          </Typography>
          <Typography className="barra">|</Typography>
          <Typography
            onClick={() => {
              window.open("/politica-privacidade.html", "_blank");
            }}>
            Política de privacidade
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
export default Rodape;
