import { Helmet } from "react-helmet";
import { retornaUrlApp } from "@/services/utils";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Rodape from "../rodape";
import BotaoPrincipal from "@/components/BotaoPrincipal";
import "./index.scss";

export default function Desenvolvedores() {
  const criarConta = () => {
    window.location.href = retornaUrlApp() + "app/cadastro/etapa/1";
  };

  return (
    <>
      <Container component="section" className="sessao-site-desenvolvedores">
        <Helmet>
          <title>Movipay - Integração API</title>
          <link rel="canonical" href="https://movipay.com.br" />
          <meta
            name="description"
            content="Integre seu sistema com a nossa API e realize suas cobranças online."
          />
        </Helmet>

        <Grid container spacing={6} maxWidth={"xl"}>
          <Grid item xs={12} lg={6} sx={{ p: 1 }}>
            <Typography variant="h1" className="titulo">
              Integre seu sistema com a API da{" "}
              <span className="movi">Movipay</span> e realize suas cobranças de
              forma fácil e segura.
            </Typography>
            <Typography className="texto-secundario" sx={{ pt: 2 }}>
              Disponibilizamos ambientes seguros e devidamente autenticados para
              que os nossos parceiros possam realizar suas transações
              tranquilamente.
            </Typography>

            <BotaoPrincipal
              className="botao"
              sx={{ mt: 2, pr: 2, pl: 2, display: "block" }}
              onClick={() => {
                criarConta();
              }}>
              QUERO ABRIR A MINHA CONTA
            </BotaoPrincipal>
            <BotaoPrincipal
              className="botao-documentacao"
              onClick={() => {
                window.open("https://movipay.docs.apiary.io/", "_blank");
              }}>
              ACESSAR DOCUMENTAÇÃO
            </BotaoPrincipal>
          </Grid>
          <Grid item xs={12} lg={6}>
            <img
              className="banner principal"
              src="/site/banner_desenvolvedores.webp"
              alt="desenvolvedores"
            />
          </Grid>
        </Grid>
      </Container>
      <Container component="section" className="sessao-site-desenvolvedores">
        <Grid container spacing={6} maxWidth={"xl"}>
          <Grid item xs={12} lg={6}>
            <img
              className="banner"
              src="/site/ambiente-sandbox.webp"
              alt="sandbox"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography className="titulo">
              Ambiente sandbox: área segura para realizar seus testes
            </Typography>
            <Typography className="texto-secundario">
              Utilize a nossa área de testes para garantir o funcionamento de
              todas as suas integrações.
            </Typography>

            <BotaoPrincipal
              className="botao-documentacao"
              onClick={() => {
                window.open(
                  "https://movipay.docs.apiary.io/#introduction/ambiente-de-testes-e-producao",
                  "_blank"
                );
              }}>
              ACESSAR DOCUMENTAÇÃO
            </BotaoPrincipal>
          </Grid>
        </Grid>
      </Container>
      <Container component="section" className="sessao-site-desenvolvedores">
        <Grid container spacing={6} maxWidth={"xl"}>
          <Grid item xs={12} lg={6}>
            <Typography className="titulo">
              Webhook: respostas em tempo real
            </Typography>
            <Typography className="texto-secundario">
              Automatize respostas para que você receba uma atualização da sua
              cobrança quando ela for paga, por exemplo.
            </Typography>

            <BotaoPrincipal
              className="botao-documentacao"
              onClick={() => {
                window.open(
                  "https://movipay.docs.apiary.io/#introduction/webhook",
                  "_blank"
                );
              }}>
              ACESSAR DOCUMENTAÇÃO
            </BotaoPrincipal>
          </Grid>
          <Grid item xs={12} lg={6}>
            <img className="banner" src="/site/webhooks.webp" alt="webhooks" />
          </Grid>
        </Grid>
      </Container>
      <Container component="section" className="sessao-site-desenvolvedores">
        <Grid container spacing={6}>
          <Grid item xs={12} lg={6}>
            <img
              className="banner"
              src="/site/whitalabel-icon.webp"
              alt="whitelabel"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography className="titulo">
              Whitelabel: crie sua própria solução personalizada
            </Typography>
            <Typography className="texto-secundario">
              Envie as cobranças para seus clientes com sua logo, identidade
              visual e subdomínio gerando mais confiança e facilidades no
              dia-a-dia
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container component="section" className="sessao-site-desenvolvedores">
        <Grid container spacing={6} maxWidth={"xl"}>
          <Grid item xs={12} lg={6}>
            <Typography className="titulo-collections">
              Acesse nossas collections
            </Typography>
            <Typography className="texto-secundario">
              Disponibilizamos nossas collections no postman para agilizar seus
              testes. Clique no botão abaixo para acessar.
            </Typography>

            <img
              title="Acessar collection postman"
              className="logo-postman"
              height={60}
              width={60}
              src="/site/logo-postman.png"
              alt="logo postman"
              loading="lazy"
              onClick={() => {
                window.open(
                  "https://app.getpostman.com/run-collection/31391503-7189ed2f-a0fa-4bad-8b28-113e03738a03?action=collection%2Ffork&source=rip_markdown&collection-url=entityId%3D31391503-7189ed2f-a0fa-4bad-8b28-113e03738a03%26entityType%3Dcollection%26workspaceId%3D227bbc3e-8d1d-482b-9f54-e5367f327b3d",
                  "_blank"
                );
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <Rodape></Rodape>
    </>
  );
}
