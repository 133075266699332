import { useRoutes } from "react-router-dom";

// project import
import SiteRoutes from "./SiteRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([SiteRoutes]);
}
