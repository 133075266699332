// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import user from "./user";
import geral from "./geral";
import dashboard from "./dashboard";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  user,
  dashboard,
  geral,
});

export default reducers;
