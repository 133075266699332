import axios from "axios";

async function perdaAutorizacao() {
  alert("Por favor, faça login novamente!");
  window.location.href = "/";
}

export function retornaUsuario(login) {
  let usuarioLogadoStorage = {};
  if (!login && !usuarioLogadoStorage) {
    perdaAutorizacao();
    return;
  }
  return usuarioLogadoStorage;
}

function retornaHeaders() {
  const authentication = JSON.parse(
    sessionStorage.getItem("authentication") || "{}"
  );
  if (authentication.expirationDate <= new Date()) {
    perdaAutorizacao();
    return;
  }
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (authentication.userToken || ""),
    },
  };
}

export function retornaUrlBase() {
  if (ehProducao()) {
    return "https://api.movipay.com.br/internal/";
    // return "https://api.homologacao.movipay.com.br/internal/";
  }
  return "http://localhost:21095/internal/";
}

export function ehHomologacao() {
  const url = retornaUrlBase();
  return url.indexOf("homologacao") !== -1;
}

export function ehProducao() {
  return process.env.NODE_ENV === "production";
}

export function post(endpoint, body) {
  var promise = new Promise((resolve, reject) => {
    if (!body) {
      body = {};
    }
    axios
      .post(retornaUrlBase() + endpoint, body, retornaHeaders())
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}

export function put(endpoint, body) {
  var promise = new Promise((resolve, reject) => {
    if (!body) {
      body = {};
    }
    axios
      .put(retornaUrlBase() + endpoint, body, retornaHeaders())
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}

const trataErro = (error, reject) => {
  if (error.response && error.response.status === 401) {
    perdaAutorizacao();
    return;
  }
  reject(error);
};
export function get(endpoint, params) {
  var promise = new Promise((resolve, reject) => {
    const config = retornaHeaders();
    config.params = params;
    axios
      .get(retornaUrlBase() + endpoint, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}

export function remove(endpoint) {
  var promise = new Promise((resolve, reject) => {
    axios
      .delete(retornaUrlBase() + endpoint, retornaHeaders())
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}
