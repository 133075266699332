import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./index.scss";
import Rodape from "../rodape";

const TermosUso = () => {
  return (
    <>
      <Container className="sessao-site sessao-termos-uso">
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} align="center">
            <Typography variant="h3" className="titulo">
              Termos de Uso
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          sx={{ m: { md: "auto" } }}
          maxWidth="md"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Typography className="subtitulo">Considerando que: </Typography>

            <Typography className="descricao">
              (i) A QuitaPay LTDA, inscrita no CNPJ sob nº 49.595.786/0001-26
              (“QuitaPay”), atua viabilizando o pagamento de boletos, com
              cartões de débito ou crédito, de forma parcelada ou à vista. (ii)
              A QuitaPay disponibiliza para os consumidores interessados
              (“Usuários”) os serviços de pagamento de boletos, um portal
              digital que pode ser acessado por meio da URL www.quitapay.com.br
              (“Portal Digital”), por meio do qual o usuário possui acesso a
              todas as informações necessárias, bem como ao efetivo parcelamento
              dos débitos. A QuitaPay disponibiliza o presente Termo de Uso e
              Outras Avenças (“Termo”), que constituirá, para todos os efeitos,
              um contrato celebrado entre a QuitaPay e qualquer pessoa que
              atenda todos os requisitos do presente Termo e deseje contratar os
              Serviços, o qual deverá ser lido integralmente, antes de sua
              aceitação.
            </Typography>
            <Typography className="subtitulo">
              Aceitação do Termo de Uso{" "}
            </Typography>

            <Typography className="descricao">
              1.1. O presente Termo contém condições de contratação e uso do
              Portal Digital para o acesso aos Serviços prestados pela QuitaPay.
              1.2. A QuitaPay, na qualidade de facilitadora, fornece ao Usuário,
              sujeito aos Termos de Uso abaixo, acesso ao Portal Digital para
              contratação de seus Serviços. 1.3. As informações contidas no
              Portal Digital poderão ser alteradas, modificadas, ajustadas ou
              extintas a qualquer momento, sem que haja a necessidade de
              informação prévia ao Usuário, sendo que essas alterações,
              modificações e/ou ajustes terão efeito somente após se tornarem
              públicos. 1.4. Ao realizar a contratação dos Serviços, o USUÁRIO
              declara que leu e concordou expressamente com a versão mais
              recente do Termo de Uso, fato este que o vinculará automaticamente
              às regras aqui contidas.
            </Typography>
            <Typography className="subtitulo">
              Descrição dos Serviços{" "}
            </Typography>

            <Typography className="descricao">
              2.1. A QuitaPay, por meio do Portal Digital, disponibiliza o
              acesso a produtos e serviços para pagamento de boletos, por meio
              de cartão de débito ou crédito, de forma parcelada ou à vista
              (“Transação”). 2.2. Para obter acesso aos Serviços, o Usuário
              deverá acessar o Portal Digital, por meio do fornecimento correto
              das informações solicitadas. 2.3. O Usuário fica desde logo
              informado e concorda que os Serviços estarão disponíveis 24 (vinte
              e quatro) horas por dia, 7(sete) dias por semana, salvo em casos
              de culpa de terceiros, caso fortuito ou força maior, e manutenções
              técnicas e/ ou operacionais que exijam o desligamento temporário
              do sistema ou impossibilitem o acesso. 2.4. Por meio do Portal
              Digital, o Usuário poderá realizar as seguintes operações: I –
              Cadastramento de dados pessoais; II – Contratação de um dos
              Serviços disponibilizados pela QuitaPay.
            </Typography>
            <Typography className="subtitulo">Cadastro do Usuário</Typography>
            <Typography className="descricao">
              3.1. Para a utilização dos Serviços, o Usuário deverá efetuar
              preenchimento de seus dados previamente no Portal Digital, no qual
              deverá fornecer informações pessoais verdadeiras e atuais, dentre
              elas, o nome ou razão social, o CPF ou CNPJ, telefone e endereço
              de e – mail e no caso de pessoas jurídicas, os dados cadastrais de
              seus sócios ou representantes legais, responsabilizando-se pela
              veracidade das informações e pela sua atualização, quando
              necessário. 3.2. Só poderão se cadastrar no Portal Digital pessoas
              físicas ou jurídicas que tenham capacidade legal para contratar e
              que sejam residentes no Brasil. Portanto, não poderão se cadastrar
              e manter-se cadastrados os menores de 18 anos, as pessoas
              civilmente incapazes, pessoas sem CPF ou sem CNPJ, ou ainda, com
              CPF ou CNPJ baixado, cancelado, não informado, nulo, suspenso,
              cujo titular tenha falecido, ou cujo sócio e / ou representante
              indicado se enquadre em alguma dessas situações ou aqueles
              Usuários que tenham sido suspensos ou inabilitados no Portal
              Digital, temporária ou definitivamente. 3.3. As informações
              pessoais fornecidos pelo Usuário deverão ser completas, com
              informações exatas, precisas e verdadeiras, sendo o Usuário o
              único responsável, civil e criminalmente, pelos dados fornecidos,
              obrigando – se, ainda, a atualizá-los sempre que necessário e/ ou
              solicitado sob pena(i) de não utilização dos Serviços;
              (ii)suspensão dos Serviços; e / ou(iii) responsabilização nas
              esferas cível, administrativa e criminal na forma prevista em Lei.
              3.4. Caso seja verificado que as informações fornecidas são falsas
              ou incompletas, QuitaPay se dá o direito de não fornecimento dos
              serviços, até que a situação seja regularizada. 3.5. A QuitaPay
              disponibiliza para os usuários, antes da contratação dos Serviços,
              a Política de Privacidade praticada pela empresa, a qual trata
              especificamente do fornecimento de dados pessoais do Usuário para
              cadastro e o seu tratamento. 3.6 O Usuário, ao contratar os
              Serviços, declara, também, que teve acesso integral e aceita os
              termos da Política de Privacidade da QuitaPay.
            </Typography>
            <Typography className="subtitulo">Condições do Serviço </Typography>

            <Typography className="descricao">
              4.1. A QuitaPay atua exclusivamente como empresa facilitadora, de
              modo a viabilizar o pagamento de débitos pelo Usuário, razão pela
              qual o parcelamento no cartão de crédito ou o pagamento à vista
              por meio de cartão de débito é de exclusiva responsabilidade do
              responsável pelo cartão utilizado. 4.2. Em caso de recurso
              deferido por uma das partes envolvidas (Usuários ou Órgão/Empresa
              Emissora do Boleto), após o pagamento do débito, haverá a
              restituição, por exclusiva responsabilidade do Órgão/Empresa
              Emissora do Boleto, apenas dos valores acertados entre essas
              partes (Usuários e Órgão/Empresa Emissora do Boleto), excluídas
              taxas administrativas relativas ao pagamento e / ou parcelamento
              via cartão. 4.3. Todas as taxas de juros envolvidas no
              parcelamento, inclusive CET quando houver, valor total do
              parcelamento e o valor de cada parcela serão informadas no Portal
              Digital durante a contratação dos Serviços. O Usuário deverá
              garantir que entendeu de forma clara as condições oferecidas pela
              QuitaPay antes da contratação dos Serviços, devendo entrar em
              contato por meio de um dos canais disponibilizados em caso de
              dúvidas. 4.4. Ao contratar os Serviços por meio da concretização
              da Transação de pagamento, o Usuário concorda expressamente com
              todos os valores e forma de pagamento oferecidos pela QuitaPay.
              4.5. Em caso de contestação da Transação pela Instituição
              Financeira, de forma posterior ao parcelamento ou pagamento do
              débito, sem motivação comprovada, o Usuário poderá sofrer sanções
              legais e administrativas, além de indenizar a QuitaPay por
              eventuais prejuízos sofridos, conforme cláusula 5.1 deste Termo.
              4.6. A QuitaPay reserva-se o direito de não processar as
              Transações que: (i) contenham dados incompletos, contraditórios ou
              inválidos; (ii) A QuitaPay, a seu exclusivo critério, considere
              envolver algum risco; (ii) estejam em desacordo ao presente Termo;
              ou (iv) não sejam autorizadas pelo banco ou pela credenciadora ou
              pelo emissor de cartões de crédito ou débito. 4.7. A QuitaPay
              poderá cancelar uma Transação sempre que verificar que a mesma:
              (i) foi invalidada pelo banco do Usuário; (ii) foi processada
              incorretamente pela QuitaPay, (iii) o Usuário não tinha
              autorização para enviá-lo, seja por fraude ou qualquer outro
              motivo, (iv) decorre de qualquer operação em desacordo com este
              Termo, ou (v) caso de suspeita de fraude ou qualquer
              irregularidade.
            </Typography>
            <Typography className="subtitulo">Indenização</Typography>
            <Typography className="descricao">
              5.1. O Usuário concorda em indenizar a QuitaPay de qualquer dano
              que esta venha a sofrer por culpa ou dolo do Usuário na utilização
              irregular ou ilegal dos Serviços e em razão do descumprimento do
              presente Termo de Uso e/ ou demais políticas da QuitaPay; ou ainda
              pela violação de qualquer lei e direito de terceiros,
              responsabilizando – se por eventuais perdas e danos. 5.2.A
              QuitaPay não poderá ser responsabilizada por qualquer falha ou não
              funcionamento dos serviços provenientes de terceiros ou
              decorrentes de caso fortuito ou força maior. 5.3.Em hipótese
              alguma a QuitaPay poderá ser responsabilizada por qualquer erro
              originado na utilização ou não utilização do Serviço.
            </Typography>
            <Typography className="subtitulo">Prazo </Typography>

            <Typography className="descricao">
              6.1. O Portal Digital estará disponível para o Usuário por prazo
              indeterminado, sendo certo que a QuitaPay poderá, à sua livre
              discrição, encerrar a prestação dos Serviços, independentemente de
              qualquer tipo de comunicação prévia ao Usuário. 6.2. Em caso de
              encerramento dos Serviços, a QuitaPay permanecerá responsável por
              todos os direitos e obrigações decorrentes de Serviços já
              contratados e não encerrados.
            </Typography>
            <Typography className="subtitulo">Disposições Gerais</Typography>
            <Typography className="descricao">
              7.1. A QuitaPay utilizará os dados e informações pessoais dos
              Usuários para prestar os Serviços, nas formas e casos
              estabelecidos na Política de Privacidade da QuitaPay. No entanto,
              além das hipóteses previstas na Política de Privacidade, a
              QuitaPay poderá coletar e revelar dados cadastrais de seus
              usuários para terceiros, incluindo, mas sem se limitar a, empresas
              integrantes do mesmo grupo econômico, parceiros comerciais,
              instituições financeiras, autoridades e pessoas físicas ou
              jurídicas(i) que alegarem ter sido lesadas por Usuários; (ii) com
              a finalidade de realizar estudos internos, (iii) que prestem
              serviços à QuitaPay e/ ou com parceiros comerciais para fins de
              negócios da QuitaPay, tais como, mas sem se limitar ao
              gerenciamento de risco, a detecção de atos ilícitos, o envio
              anúncios personalizados, a oferta conjunta de produtos ou
              serviços, a análise de dados estatísticos, dentre outros; (iv)que
              prestem serviços de valor agregado que se integrem dentro do
              Portal Digital ou mediante links a outros sites eletrônicos da
              Internet, para atender necessidades dos Usuários relacionadas aos
              Serviços; (iv) com o objetivo de propor iniciativas comerciais e
              promocionais da QuitaPay. Para mais informações, consulte as
              Políticas de Privacidade da QuitaPay, que passam a fazer parte
              integrante dos presente Termo. 7.2. O Usuário autoriza de forma
              irrevogável a QuitaPay ou quem a QuitaPay designe, a gravar todas
              as operações e / ou telefonemas do Usuário relativos aos serviços
              prestados pela QuitaPay, e a utilizar essas gravações como meio
              probatório perante autoridades administrativas ou judiciais. Os
              registros emitidos pela QuitaPay serão considerados prova
              suficiente do cumprimento das Transações, substituem a necessidade
              de qualquer recibo e são plenamente oponíveis ao Usuário. 7.3. A
              não exigência, por qualquer uma das partes, do cumprimento de
              qualquer cláusula ou condição estabelecida, será considerada mera
              tolerância, não implicando na sua novação, e tão pouco na
              abdicação do direito de exigi-la no futuro, não afetando a
              validade deste instrumento e quaisquer de suas condições. 7.4. Os
              regulamentos relativos a eventuais campanhas promocionais,
              propagandas de incentivo e outros programas que propiciem
              benefícios adicionais ao Usuário serão divulgados separadamente,
              sendo que, por mera liberalidade, alguns serviços poderão ser
              oferecidos gratuitamente ou com variações dos preços e condições
              regularmente ofertados a título promocional e por prazo
              determinado. 7.5. Na hipótese de qualquer uma das disposições
              deste Termo vir a ser considerada contrária à lei brasileira, por
              qualquer autoridade governamental ou decisão judicial, as demais
              disposições não afetadas continuarão em vigor e as partes deverão
              alterar este instrumento de forma a adequá-lo a tal lei ou à
              decisão judicial. 7.6. Este Termo obriga as partes e seus
              sucessores a qualquer título. 7.7. O Usuário reconhece que poderão
              ser veiculados anúncios e propagandas de terceiros e que, caso
              venha a adquirir qualquer produto ou serviço lá anunciado, caberá
              ao anunciante a responsabilidade por quaisquer produtos ou
              serviços adquiridos, bem como pelo conteúdo publicado. 7.8. O
              usuário autoriza a QuitaPay a utilizar recursos, tais como, mas
              não se limitando, a & quot; cookies & quot; (pequenas mensagens
              comerciais instantâneas), a fim de oferecer um Serviço melhor e
              mais personalizado. 7.9. O Usuário declara, ainda, que cumpre e
              faz cumprir, bem como suas afiliadas, acionistas, conselheiros,
              diretores, funcionários, agentes e/ ou eventuais
              subcontratados(“Pessoas Relacionadas”), todas as leis, regras,
              regulamentos e normas aplicáveis, emitidos por qualquer jurisdição
              aplicável ao Usuário, que versam sobre atos de corrupção, suborno
              e atos lesivos contra a administração pública, incluindo, mas não
              se limitando a Lei nº 12.846 / 13(“Leis Anticorrupção”); na medida
              em que(i) mantém políticas e procedimentos internos que assegurem
              integral cumprimento das Leis Anticorrupção; (ii)dá pleno
              conhecimento das Leis Anticorrupção a todos os profissionais que
              venham a ser relacionar com o Usuário, conforme aplicável;
              (iii)abstém – se de praticar quaisquer atos estabelecidos nas Leis
              Anticorrupção, no seu interesse ou para seu benefício, exclusivo
              ou não; e(iv) caso tenha conhecimento de qualquer ato ou fato que
              viole as Leis Anticorrupção, comunicará imediatamente a QuitaPay,
              que poderá tomar todas as providências que entender necessárias.
              7.10. O presente Termo de Uso será interpretado e regido conforme
              a legislação brasileira e aplicado em todo território nacional.
              7.11. Este Termo de Uso rege a relação entre o Usuário e a
              QuitaPay enquanto ativo o parcelamento ou pagamento contratado
              pelo Usuário ou seu cadastro no Portal Digital.
            </Typography>
            <Typography className="subtitulo">Foro </Typography>

            <Typography className="descricao">
              8.1. As partes elegem o foro da Cidade de Horizontina, Estado do
              Rio Grande do Sul, para dirimir eventuais dúvidas ou controvérsias
              decorrentes do presente Contrato, excluindo-se qualquer outro, por
              mais privilegiado que seja. Taxas e Prazos 9.1. Nossas taxas de
              juros são variáveis e podem ser alteradas conforme for de desejo
              da QuitaPay e o(s) boleto9s) pode ser pago em até 12 vezes.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className="contato"
          style={{ marginTop: "3rem", cursor: "pointer" }}
          onClick={() => {
            window.open(
              "https://api.whatsapp.com/send?phone=5555999042290&text=Ol%C3%A1. Preciso de ajuda com o portal!"
            );
          }}
        >
          <Grid item xs={12}>
            <Typography align="center" className="contato-texto">
              Ficou com dúvidas?
            </Typography>{" "}
            <Typography align="center" className="contato-texto">
              Entre em contato conosco:
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <img src="/whatsapp.png" alt=""></img>
          </Grid>
        </Grid>
      </Container>

      <Rodape></Rodape>
    </>
  );
};

export default TermosUso;
