import SiteLayout from "@/layout/SiteLayout";
import Inicio from "@/pages/site/Inicio";
import Ajuda from "@/pages/site/Ajuda";
import TermosUso from "@/pages/site/TermosUso";
import Sobre from "@/pages/site/Sobre";
import Desenvolvedores from "../pages/site/Desenvolvedores";

const SiteRoutes = {
  path: "/",
  element: <SiteLayout />,
  children: [
    {
      path: "/",
      element: <Inicio />,
    },
    {
      path: "/termos-de-uso",
      element: <TermosUso />,
    },
    {
      path: "/sobre",
      element: <Sobre />,
    },
    {
      path: "/como-funciona",
      element: <Ajuda />,
    },
    {
      path: "/desenvolvedores",
      element: <Desenvolvedores />,
    },
    {
      path: "*",
      element: <Inicio />,
    },
  ],
};

export default SiteRoutes;
