import { ehHomologacao } from "@/services/api";

export function ehProducao() {
  return process.env.NODE_ENV === "production";
}

export function retornaUrlApp() {
  if (ehHomologacao()) {
    return "https://app.homologacao.movipay.com/";
  }

  if (ehProducao()) {
    return "https://app.movipay.com.br/";
  }

  return "http://localhost:3007/";
}

export function direcionaParaApp(url) {
  window.location.href = `${retornaUrlApp()}${url}`;
}
