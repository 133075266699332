import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { resetGeral } from "@/store/reducers/geral";
import { resetUser } from "@/store/reducers/user";
import { useDispatch } from "react-redux";
import { retornaUrlApp } from "@/services/utils";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@/components/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Rodape from "../rodape";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import BotaoPrincipal from "@/components/BotaoPrincipal";
import Typography from "@mui/material/Typography";
import "./index.scss";

const Inicio = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");
  const criarConta = () => {
    window.location.href = retornaUrlApp() + "app/cadastro/etapa/1";
  };

  useEffect(() => {
    dispatch(resetGeral());
    dispatch(resetUser());
  }, [dispatch]);
  return (
    <>
      <Container id="inicio" component="main" className="sessao-site sessao-1">
        <Helmet>
          <title>Movipay</title>
          <link rel="canonical" href="https://movipay.com.br" />
          <meta
            name="description"
            content="Cobre seus clientes de forma rápida e fácil."
          />
        </Helmet>
        <Grid
          maxWidth={"xl"}
          container
          sx={{ p: 3, m: "auto", alignItems: "center" }}
          id="inicio">
          <Grid item xs={12} sm={6} sx={{ p: 1 }}>
            <Typography
              variant="h1"
              className="titulo"
              sx={{ pt: { xs: 0, md: 5 } }}>
              AGORA É FÁCIL PAGAR E RECEBER{" "}
              <span className="destaque">ONLINE!</span>
            </Typography>
            <Typography className="texto-secundario" sx={{ pt: 2 }}>
              Automatizamos o seu processo para que você não perca nenhuma venda
            </Typography>
            <BotaoPrincipal
              className="botao botao-abrir-conta"
              sx={{ mt: 2, pr: 2, pl: 2, display: "block" }}
              onClick={() => {
                criarConta();
              }}
              id="como-funciona">
              QUERO ABRIR A MINHA CONTA
            </BotaoPrincipal>
          </Grid>
          <Grid
            item
            sm={6}
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
            }}>
            <img
              className="banner"
              src="/site/banner.webp"
              alt="logo_movipay"
            />
          </Grid>
        </Grid>
      </Container>
      <Container className="sessao-site sessao-2">
        <Grid container spacing={3} sx={{ p: 3 }} justifyContent="center">
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: { xs: "none", sm: "flex" } }}>
            <img
              className="imagem-sessao-2"
              src="/site/como-funciona.png"
              alt=""
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography sx={{ fontWeight: "bold", fontSize: "2rem" }}>
              Cobrar e receber pelas suas vendas de forma rápida e fácil!
            </Typography>
            <Typography color="primary" sx={{ fontSize: "1.5rem" }}>
              Entenda como funciona
            </Typography>
            <Accordion className="como-funciona-items">
              <AccordionSummary
                className="titulo-item-como-funciona"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Grid container spacing={2} alignItems={"center"}>
                  <Grid item xs={2.5} sm={2}>
                    <Typography variant="h3">
                      <img
                        src="/site/money-outlined.png"
                        width={50}
                        height={50}
                        alt=""
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={9.5} sm={10}>
                    <Typography variant="h3">Crie a cobrança</Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="descricao">
                  Crie a cobrança para seu cliente por PIX ou cartão. Não perca
                  o cliente que precisa parcelar e ainda garanta o recebimento.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="como-funciona-items">
              <AccordionSummary
                className="titulo-item-como-funciona"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header">
                <Grid container spacing={2} alignItems={"center"}>
                  <Grid item xs={2.5} sm={2}>
                    <Typography variant="h3">
                      <img
                        src="/site/person-outlined.png"
                        width={50}
                        height={50}
                        alt=""
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={9.5} sm={10}>
                    <Typography variant="h3">Cliente paga</Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="descricao">
                  Pelo link enviado, seu cliente escolhe a forma e realiza o
                  pagamento.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="como-funciona-items">
              <AccordionSummary
                className="titulo-item-como-funciona"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header">
                <Grid container spacing={2} alignItems={"center"}>
                  <Grid item xs={2.5} sm={2}>
                    <Typography variant="h3">
                      <img
                        src="/site/billet-outlined.png"
                        width={50}
                        height={50}
                        alt=""
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={9.5} sm={10}>
                    <Typography variant="h3">Resgate o saldo atual</Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="descricao">
                  Transfira seus recebimentos para sua conta bancária garantindo
                  o fluxo de caixa.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Grid item xs={12}>
              <BotaoPrincipal
                variant="contained"
                className="botao botao-abrir-conta"
                size="large"
                sx={{ mt: 2, pr: 2, pl: 2, display: "block" }}
                onClick={() => {
                  criarConta();
                }}>
                QUERO ABRIR A MINHA CONTA
              </BotaoPrincipal>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container className="sessao-site sessao-3">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography>
              <img src="/site/money-filled.png" width={80} height={80} alt="" />
            </Typography>

            <Typography className="titulo-facilidades color-movipay">
              Centralize seus ganhos
            </Typography>
            <Typography className="subtitulo">
              Gerencie seus ganhos e todos os seus pagamentos em aberto ou
              realizados!
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>
              <img
                src="/site/extract-filled.png"
                width={80}
                height={80}
                alt=""
              />
            </Typography>

            <Typography className="titulo-facilidades color-movipay">
              Processo de venda
            </Typography>
            <Typography className="subtitulo">
              Conecte ao seu sistema de fluxo de caixa através da nossa API
              própria.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>
              <img src="/site/card-filled.png" width={80} height={80} alt="" />
            </Typography>

            <Typography className="titulo-facilidades color-movipay">
              Saque seus ganhos
            </Typography>
            <Typography className="subtitulo">
              Com segurança e tranquilidade saque os seus ganhos.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container className="sessao-site sessao-4">
        <Grid container spacing={3} className="formas-pagamentos">
          <Grid item xs={12} sm={4.5}>
            <Typography sx={{ fontSize: "1.5rem" }} color="primary">
              Formas de pagamento
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "2rem" }}>
              Ofereça para o seu cliente a possibildade de escolher a forma de
              pagamento
            </Typography>
            <Typography sx={{ fontSize: "1rem" }}>
              Tudo isso sem precisar de maquininha!
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7.5}>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={6} sm={4} align="center">
                <Typography>
                  <img
                    src="/site/money-filled.png"
                    width={100}
                    height={100}
                    alt=""
                  />
                </Typography>
                <Typography className="opcao-pagamento color-movipay">
                  PIX
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4} align="center">
                <Typography>
                  <img
                    src="/site/card-filled.png"
                    width={100}
                    height={100}
                    alt=""
                  />
                </Typography>
                <Typography className="opcao-pagamento color-movipay">
                  Cartão de crédito
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} align="center">
                <Typography>
                  <img
                    src="/site/billet-filled.png"
                    width={100}
                    height={100}
                    alt=""
                  />
                </Typography>
                <Typography className="opcao-pagamento color-movipay">
                  Boleto bancário
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Rodape></Rodape>
    </>
  );
};

export default Inicio;
