import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "@/components/Spinner";
import { Box, Toolbar } from "@mui/material";
import Toaster from "@/components/Toaster";
import Header from "./Header";

// ==============================|| MINIMAL LAYOUT ||============================== //

const SiteLayout = () => {
  const geral = useSelector((state) => state.geral);
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header></Header>
      <Box
        component="main"
        sx={{ width: "100%", flexGrow: 1 }}
        alignItems="center">
        <Toolbar></Toolbar>
        <Outlet />
      </Box>

      <Spinner {...{ showSpinner: geral.showSpinner }}></Spinner>
      <Toaster {...{ infoToaster: geral.infoToaster }}></Toaster>
    </Box>
  );
};

export default SiteLayout;
