import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./index.scss";
import Rodape from "../rodape";
import Button from "@mui/material/Button";
import { Helmet } from "react-helmet";
import { direcionaParaApp } from "@/services/utils";

const Sobre = () => {
  const criarConta = () => {
    direcionaParaApp("/app/cadastro/etapa/1");
  };
  return (
    <>
      <Helmet>
        <title>Sobre nós</title>
        <link rel="canonical" href="https://movipay.com.br/sobre-nos" />
      </Helmet>

      <Container className="sessao-site sessao-sobre">
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} sx={{ mt: 4 }} align="center">
            <Typography variant="h3" className="titulo">
              REFERÊNCIA NACIONAL
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          sx={{ m: { md: "auto" } }}
          maxWidth="md"
          justifyContent="center">
          <Grid item xs={12} align="center">
            <Typography className="descricao">
              A MoviPay é uma fintech que faz parte do grupo Quita Tecnologia
              LTDA.
            </Typography>
            <Typography className="descricao">
              Estamos situados em Horizontina/RS e atendemos todo Brasil com
              nossas soluções em cobranças.
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Button
              variant="contained"
              className="botao botao-simular-principal"
              size="large"
              sx={{ mt: 2, pr: 2, pl: 2, display: "block" }}
              onClick={() => {
                criarConta();
              }}>
              CRIE SUA CONTA NA MOVIPAY
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className="contato"
          style={{ marginTop: "3rem", cursor: "pointer" }}
          onClick={() => {
            window.open(
              "https://api.whatsapp.com/send?phone=5555999042290&text=Ol%C3%A1. Preciso de ajuda com o portal!"
            );
          }}>
          <Grid item xs={12}>
            <Typography align="center" className="contato-texto">
              Ficou com dúvidas?
            </Typography>{" "}
            <Typography align="center" className="contato-texto">
              Entre em contato conosco:
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <img src="/whatsapp.png" alt=""></img>
          </Grid>
        </Grid>
      </Container>

      <Rodape></Rodape>
    </>
  );
};

export default Sobre;
