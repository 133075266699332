import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { retornaUrlApp } from "@/services/utils";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import BotaoText from "@/components/BotaoText";
import BotaoPrincipal from "@/components/BotaoPrincipal";
import "./Header.scss";

function Header() {
  const navigation = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const abrirAction = (page) => {
    handleCloseNavMenu();
    navigation(page);
  };

  const cadastrar = () => {
    window.location.href = retornaUrlApp() + "app/cadastro/etapa/1";
  };

  const entrar = () => {
    window.location.href = retornaUrlApp() + "app/login";
  };

  return (
    <AppBar
      className="toolbar"
      style={{
        position: "fixed",
        color: "white",
        elevation: 0,
        sx: {},
      }}>
      <Container>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              color="black"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}>
              <MenuItem
                onClick={() => {
                  abrirAction("/");
                }}>
                <Typography textAlign="center">INÍCIO</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  abrirAction("/ajuda");
                }}>
                <Typography textAlign="center">COMO FUNCIONA</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigation("/desenvolvedores");
                }}>
                <Typography textAlign="center">DESENVOLVEDORES</Typography>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  entrar();
                }}>
                <Typography textAlign="center">ENTRAR</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}>
            <img
              src="/site/logo_cabecalho.png"
              alt=""
              className="logo-quita"
              onClick={() => {
                navigation("/");
              }}></img>
          </Typography>
          <Grid container sx={{ display: { xs: "none", md: "flex" } }}>
            <Grid item md={3}>
              <Box
                sx={{
                  pl: 1,
                  pt: 2,
                }}>
                <img
                  src="/site/logo_cabecalho.png"
                  alt=""
                  className="logo-quita"
                  onClick={() => {
                    navigation("/");
                  }}></img>
              </Box>
            </Grid>
            <Grid item md={9}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  justifyContent: "flex-end",
                }}>
                <BotaoText
                  className="botao"
                  onClick={() => {
                    window.location.href = "/#inicio";
                  }}
                  sx={{ my: 1, pl: 2, display: "block" }}>
                  INÍCIO
                </BotaoText>

                <BotaoText
                  className="botao"
                  onClick={() => {
                    window.location.href = "/#como-funciona";
                  }}
                  sx={{ my: 1, pl: 2, display: "block" }}>
                  SOLUÇÕES
                </BotaoText>

                <BotaoText
                  className="botao"
                  onClick={() => {
                    navigation("/desenvolvedores");
                  }}
                  sx={{ my: 1, pl: 2, display: "block" }}>
                  DESENVOLVEDORES
                </BotaoText>

                <BotaoText
                  className="botao"
                  onClick={() => {
                    entrar();
                  }}
                  sx={{ my: 1, pl: 2, display: "block" }}>
                  ACESSAR MINHA CONTA
                </BotaoText>

                <BotaoPrincipal
                  className="botao botao-cadastrar"
                  onClick={cadastrar}
                  sx={{ my: 1, ml: 4, mr: 2, display: "block" }}>
                  CRIAR CONTA
                </BotaoPrincipal>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
